import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./TestimonialCard.module.scss";
import { ReactComponent as QuoteIcon } from "../../../asset/images/quote.svg";

interface TestimonialCardProps {
  className?: string;
  logoUrl?: string;
  photoUrl?: string;
  text: string;
  name: string;
  role?: string;
}

const TestimonialCard: FunctionComponent<TestimonialCardProps> = ({
  className,
  logoUrl,
  photoUrl,
  text,
  name,
  role,
}) => {
  return (
    <article className={classNames(styles["testimonial-card"], className)}>
      <div className={classNames(styles["testimonial-card__part-top"])}>
        <div className={classNames(styles["testimonial-card__icon"])}>
          <QuoteIcon />
        </div>

        {logoUrl && (
          <div className={classNames(styles["testimonial-card__logo"])}>
            <img src={logoUrl} alt="Testimonial card logo" />
          </div>
        )}
      </div>

      <div className={classNames(styles["testimonial-card__part-middle"])}>
        <div className={classNames(styles["testimonial-card__text"])}>
          <p
            className="text text--body-2"
            style={{ color: "var(--color-gray-800)" }}
          >
            {text}
          </p>
        </div>
      </div>

      <div className={classNames(styles["testimonial-card__part-bottom"])}>
        {photoUrl && (
          <div className={classNames(styles["testimonial-card__photo"])}>
            <img src={photoUrl} alt="Testimonial author" />
          </div>
        )}

        <div className={classNames(styles["testimonial-card__author-info"])}>
          <div
            className={classNames(
              styles["testimonial-card__author-info__name"]
            )}
          >
            <p className="text text--body-1 text--bold">{name}</p>
          </div>

          {role && (
            <div
              className={classNames(
                styles["testimonial-card__author-info__role"]
              )}
            >
              <p className="text text--body-2">{role}</p>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default TestimonialCard;
