import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_3.svg';
import classNames from 'classnames';
import styles from './SignUpCreateAccount.module.scss';
import Input from '../../../../../components/inputs/Input/Input';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Checkbox from '../../../../../components/inputs/CheckboxList/_parts/Checkbox/Checkbox';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import { isEmailValid } from '../../../../../utils/regex';
import api from '../../../../../api/index';
import { ApiResponseDTO } from '../../../../../dto/api';
import { isAxiosError } from 'axios';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import { localStorageRemoveItem, localStorageSetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import RoutesEnum from '../../../../../enums/routes';

interface SignUpCreateAccountProps {}

const SignUpCreateAccount: FunctionComponent<SignUpCreateAccountProps> = () => {
  const navigate = useNavigate();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();

  useEffect(() => {
    localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_ID);
    localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
    localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP);
  }, []);

  const isMobile = useMediaQuery('(max-width: 1279px)');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    setErrorMessage('');
    setEmail(e.target.value);
  };

  const handleCheckboxOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setCheckboxChecked(e.target.checked);
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setErrorMessage('');
    setSuccessMessage('');
    setEmailError(false);

    if (email === '') {
      setErrorMessage('Enter your email');
      setEmailError(true);
      return;
    }

    if (isEmailValid(email) === false) {
      setErrorMessage('Enter valid email');
      setEmailError(true);
      return;
    }

    if (checkboxChecked === false) {
      setErrorMessage('Terms of Use is unchecked');
      return;
    }

    // API LOGIC
    await handleFormSubmitLoader(async () => {
      try {
        const response = await api.Auth.register({ email: email, termsOfUse: checkboxChecked });

        const responseData = response.data as ApiResponseDTO<{ userId: string; email: string }>;

        if (responseData.success === true && responseData.data) {
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_ID, responseData.data.userId);
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_EMAIL, responseData.data.email);
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '2');

          // localStorage.setItem('userId', responseData.data.userId);
          // localStorage.setItem('userEmail', responseData.data.email);
          // localStorage.setItem('userSignUpStep', '2');

          setSuccessMessage('All good. Proceeding to next step...');
          navigate(`/${RoutesEnum.SIGN_UP}/${RoutesEnum.CONFIRM_EMAIL}`);
        } else {
          console.error('Registration failed:', response.error);
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const responseObj = error.response?.data as ApiResponseDTO<
            { userId: string; email: string },
            { redirectionLink: string }
          >;

          const responseData = responseObj.data;

          setErrorMessage(responseObj.errorMessage);

          if (responseData) {
            localStorage.setItem('userId', responseData.userId);
            localStorage.setItem('userEmail', responseData.email);
            setSuccessMessage('');

            if (responseObj.meta?.redirectionLink) {
              if (responseObj.meta?.redirectionLink === 'VerificationCode') {
                localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '2');
                // localStorage.setItem('userSignUpStep', '2');
                navigate(`/${RoutesEnum.SIGN_UP}/${RoutesEnum.CONFIRM_EMAIL}`);
              }

              if (responseObj.meta?.redirectionLink === 'SetPassword') {
                localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '3');
                // localStorage.setItem('userSignUpStep', '3');
                navigate(`/${RoutesEnum.SIGN_UP}/${RoutesEnum.CREATE_PASSWORD}`);
              }

              if (responseObj.meta?.redirectionLink === 'NameAndRoles') {
                localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '4');
                // localStorage.setItem('userSignUpStep', '4');
                navigate(`/${RoutesEnum.SIGN_UP}/${RoutesEnum.NAME_AND_ROLES}`);
              }
            }
          }
        } else {
          console.error('Error:', error);
        }
      }
    });
    // API LOGIC END
  };

  const { changeWhatIsPopUpActiveState } =
    useOutletContext<{
      changeWhatIsPopUpActiveState;
    }>();

  return (
    <div className={styles['container']}>
      <div className={styles['container-top-part']}>
        <NavigationLink
          path="/"
          iconLeft={<ArrowIcon style={{ width: '11px', transform: 'rotate(180deg)' }} />}
          withBorder={false}
          colorType="white"
        >
          Back to Homepage
        </NavigationLink>
      </div>

      <div className={styles['content']}>
        <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
          <AstronomicLoader variation="blurredBackground" color="onBlue" />
        </CSSTransitionWrapper>

        <div className={styles['top-row']}>
          <div className={styles['astronomic-logo']}>{<AstronomicLogo />}</div>
          {isMobile === true && (
            <MainButton sizeType="medium" visualType="white" onClick={() => changeWhatIsPopUpActiveState(true)}>
              What is Astronomic?
            </MainButton>
          )}
        </div>
        <div className={styles['title']}>
          <h3 className="text text--h3">Join Astronomic 🚀</h3>
        </div>
        <form className={classNames(styles['form'], 'input-layout')} onSubmit={handleOnSubmit}>
          <div className="input-layout__row">
            <Input
              value={email}
              onChange={handleEmailOnChange}
              size="large"
              color="gray"
              placeholder="Enter your email"
              error={emailError}
              iconLeft={<MailIcon style={{ width: '22px' }} />}
            />

            {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
            {successMessage && <InputMessage messageState="success">{successMessage}</InputMessage>}
          </div>

          <div className={classNames('input-layout__row input-layout__row--large-margin', styles['row-submit'])}>
            <MainButton sizeType="large" visualType="main" disabled={isLoading}>
              Create account
            </MainButton>
          </div>

          <div className={classNames('input-layout__row input-layout__row--large-margin', styles['acceptance'])}>
            <Checkbox
              checked={checkboxChecked}
              onChange={handleCheckboxOnChange}
              disabled={false}
              sizeSmall={true}
              name="reg_form_terms_of_use"
              value="accepted_terms_of_use"
              label={
                <p className={styles['acceptance__label']}>
                  By clicking 'Create account' you agree to our{' '}
                  <NavigationLink path="/terms-of-use" withBorder={true} sizeType="default">
                    Terms of Use.
                  </NavigationLink>
                </p>
              }
            />
          </div>
        </form>
        <div className={styles['sign-in']}>
          <p className="text text--body-2">Already a member? </p>

          <NavigationLink path="/sign-in" sizeType="small" className={styles['sign-in__link']}>
            Sign in
          </NavigationLink>
        </div>
      </div>

      <div className={styles['container-bottom-part']}></div>
    </div>
  );
};

export default SignUpCreateAccount;
