import { FunctionComponent, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./CaseStudyCard.module.scss";
import { NavLink } from "react-router-dom";
import MainButton from "../../buttons/MainButton/MainButton";
import { ReactComponent as InfoIcon } from "../../../asset/images/icons/info.svg";

interface CaseStudyCardProps {
  imageUrl?: string;
  price?: string;
  title: string;
  text: string;
  link: string;
  logo?: React.ReactElement;
}

const CaseStudyCard: FunctionComponent<CaseStudyCardProps> = ({
  imageUrl,
  price,
  title,
  text,
  link,
  logo,
}) => {
  const [mQuery, setMQuery] = useState<any>({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addListener(setMQuery);
    // this is the cleanup function to remove the listener
    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  return (
    <div
      className={classNames(
        styles["case-study-card"],
        "button-container-for-hover"
      )}
    >
      <NavLink to={link}>
        <div className={styles["case-study-card__image"]}>
          <img src={imageUrl} />
        </div>

        <div className={styles["case-study-card__info"]}>
          <div className={styles["case-study-card__info__title"]}>
            <h4 className="text text--h4">{title}</h4>
          </div>

          <div className={styles["case-study-card__info__text"]}>
            <h4 className="text text--body-2">{text}</h4>
          </div>
        </div>

        <div className={styles["case-study-card__footer"]}>
          <div className={styles["case-study-card__footer__button"]}>
            <MainButton
              sizeType="medium"
              visualType={mQuery && !mQuery.matches ? "main" : "white"}
            >
              Read case study
            </MainButton>
          </div>

          <div className={styles["case-study-card__footer__logo"]}>{logo}</div>
        </div>

        <div className={styles["case-study-card__price"]}>
          <div className={styles["case-study-card__price__text"]}>
            <h4 className="text text--body-2 text--bold">{price}</h4>
          </div>
          <div className={styles["case-study-card__price__icon"]}>
            <InfoIcon />
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default CaseStudyCard;
