import { FunctionComponent } from 'react';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import MainButton from '../../components/buttons/MainButton/MainButton';
import RoutesEnum from '../../enums/routes';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';

interface ScheduleMeetingViewProps extends IWithUserRoleProps {}

const ScheduleMeetingView: FunctionComponent<ScheduleMeetingViewProps> = ({ user_role, router }) => {
  return (
    <MainTemplate>
      <HeroSection
        title="Schedule a meeting template"
        text="Description"
        sectionVariation="var-1-b"
        breadcrumbs={<Breadcrumbs userRole={user_role} currentLinkText="Schedule a meeting template" />}
        illustration={<></>}
      />
    </MainTemplate>
  );
};

export default with_user_role(ScheduleMeetingView);
