import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import Grid from '../../components/blocks/Grid/Grid';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';

import { ReactComponent as ContDinnersIll } from '../../asset/images/connect_ill_1.svg';
import { ReactComponent as CalendarIcon } from '../../asset/images/icons/calendar.svg';
import { ReactComponent as SlackIcon } from '../../asset/images/icons/slack.svg';
import { ReactComponent as PledgeIcon } from '../../asset/images/icons/pledge.svg';
import { ReactComponent as LightningIcon } from '../../asset/images/icons/lightning.svg';
import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people.svg';
import { ReactComponent as ThumbsUpIcon } from '../../asset/images/icons/thumbs_up.svg';
import { ReactComponent as CaseIcon } from '../../asset/images/icons/case.svg';
import { ReactComponent as BubbleIcon } from '../../asset/images/icons/bubble.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';

interface ConstellationDinnersViewProps extends IWithUserRoleProps {}

const ConstellationDinnersView: FunctionComponent<ConstellationDinnersViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplate color="white">
      <HeroSection
        title="Dine with relevant founders and investors in your city"
        text="We’ll match you with other founders and investors by industry, business type, revenue stage, and growth goals for spirited topical discussions over a healthy meal."
        sectionVariation="var-1-a"
        illustration={<ContDinnersIll />}
        buttonGroup={[
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          ),
          <MainButton
            sizeType={'large'}
            visualType="white"
            onClick={() => {
              router.navigate(`/${user_role}/${RoutesEnum.ABOUT}`);
            }}
          >
            What is Astronomic?
          </MainButton>,
        ]}
        breadcrumbs={<Breadcrumbs userRole={user_role} />}
      />

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Available on All Plans" title="Standard Features" />}
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Monthly invitations"
              text={
                <>
                  <b>Every month</b>, we’ll send the dinner dates for your city.
                </>
              }
              icon={<CalendarIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Slack group for your table"
              text={
                <>
                  We’ll create a <b>Slack group</b> and <b>calendar invite</b> for your table with everyone’s Linkedins
                  and websites.
                </>
              }
              icon={<SlackIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Ethics Pledge"
              text={
                <>
                  As startup founders ourselves, all of our team members sign an ethics pledge to work <b>honestly</b>{' '}
                  and <b>efficiently</b> to serve our clients’ needs.
                </>
              }
              icon={<PledgeIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Perfect match for dinner"
              text={
                <>
                  After everyone RSVPs, <b>we’ll match you intoa 6-person dinner</b>. Each guest will share at least1
                  attribute (business type, industry, revenue stage, growth goal).
                </>
              }
              icon={<LightningIcon />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <div className="invisible-divider"></div>

      <ChildrenWithHeaderSection title={<TitleWithSecTitle title="Format" />}>
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="6 people per dinner"
              text="6 founders, or 5 founders and 1 investor, or 4 founders and 2 investors"
              icon={<PeopleIcon path="red" />}
              iconBgColor="#F4B84020"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Reasonably priced restaurants"
              text="We book tables at restaurants with $20-$30 entrees"
              icon={<ThumbsUpIcon />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Matched by business type, industry, and revenue stage"
              text="We book tables at restaurants with $20-$30 entrees"
              icon={<CaseIcon fill="var(--color-green)" />}
              iconBgColor="#80D0B820"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Facilitated conversations"
              text="Each dinner conversation is peer-facilitated"
              icon={<BubbleIcon />}
              iconBgColor="#7AD2E720"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Dine with us!"
              text="Take your business to the next level over a healthy meal."
              icon={<RocketIcon style={{ width: '23px', fill: 'var(--color-main)' }} />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <SectionDivider color="gray" />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <h2 className="text text--h2">Testimonials</h2>
          </SimpleTitle>
        }
        backgroundColor="gray"
      >
        <Slider
          sliderSlides={[
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(ConstellationDinnersView);
