import { LOCAL_STORAGE_KEYS } from "../enums/localStorageKeys";

export const localStorageGetItem = (key: LOCAL_STORAGE_KEYS): string | null => {
  return localStorage.getItem(key);
};

export const localStorageSetItem = (key: LOCAL_STORAGE_KEYS, value: string | null): void => {
  if (value) {
    localStorage.setItem(key, value);
  }
};

export const localStorageRemoveItem = (key: LOCAL_STORAGE_KEYS): void => {
  if (localStorageGetItem(key)) {
    localStorage.removeItem(key);
  } else {
    // console.error((`There is no ${key} in localStorage`));
  }
};

export const localStorageClearUserItems = (): void => {
  localStorageRemoveItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  localStorageRemoveItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_ID);
}

export const localStorageGetUserAuthItems = (): { at?: string | null, rt?: string | null, userId?: string | null } => {
  const at = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const rt = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  return {
    at,
    rt,
    userId,
  };
}