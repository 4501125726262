import { FunctionComponent, useState, useEffect } from 'react';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import SlickSlider from 'react-slick';
import { ReactComponent as Illustration } from '../../../../../asset/images/sign_up_ill_new.svg';
import classNames from 'classnames';
import styles from './SignUpSliderWithIllustration.module.scss';
import { useMatch } from 'react-router-dom';
import useMediaQuery from '../../../../../hooks/useMediaQuery';

interface SignUpSliderWithIllustrationProps {
  changeWhatIsPopUpActiveState(boolean): void;
}

const SignUpSliderWithIllustration: FunctionComponent<SignUpSliderWithIllustrationProps> = ({
  changeWhatIsPopUpActiveState,
}) => {
  const isMobile = useMediaQuery('(max-width: 1279px)');

  const [illustrationActivated, setIllustrationActivated] = useState(false);

  const match = useMatch('/sign-up/confirm-email');

  useEffect(() => {
    if (match !== null) {
      setIllustrationActivated(true);
    } else {
      setIllustrationActivated(false);
    }
  }, [illustrationActivated, match]);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderSlides = [
    'Tempor aliquam amet odio porttitor ornare amet sed. Eu nulla at tempus.',
    'Tempor aliquam amet odio porttitor ornare amet sed. Eu nulla at tempus. [2]',
    'Tempor aliquam amet odio porttitor ornare amet sed. Eu nulla at tempus. [3]',
  ];

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['content']}>
          {isMobile === false && (
            <div className={styles['header-button']}>
              <MainButton sizeType="medium" visualType="blank" onClick={() => changeWhatIsPopUpActiveState(true)}>
                What is Astronomic?
              </MainButton>
            </div>
          )}

          <div
            className={classNames(
              styles['illustration'],
              illustrationActivated === true && styles['illustration--active'],
              'gsap__ill'
            )}
          >
            <Illustration />
          </div>

          {/* <div className={styles['slider-container']}>
            <div className={styles['slider-container__title']}>
              <h4 className="text text--h4">Enjoy all the benefits of being an Astronomic member</h4>
            </div>

            <div className={styles['slider-container__slider']}>
              <SlickSlider {...sliderSettings}>
                {sliderSlides.map((item, i) => (
                  <div className={styles['slider-container__slider__item']} key={i}>
                    <p className="text text--body-2">{item}</p>
                  </div>
                ))}
              </SlickSlider>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignUpSliderWithIllustration;
