import { FunctionComponent, useEffect, useState } from 'react';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import { ReactComponent as EditIcon } from '../../../../../asset/images/icons/edit.svg';
import { ReactComponent as TrashCanIcon } from '../../../../../asset/images/icons/trash_can.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../../asset/images/icons/external_link.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Label from '../../../../../components/texts/Label/Label';
import styles from './AccountRole.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';

interface AccountRoleProps {}

const AccountRole: FunctionComponent<AccountRoleProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [roleInfo, setRoleInfo] = useState<Array<any>>();

  const searchParams = new URLSearchParams(location.search);
  const roleId = searchParams.get('roleId');
  const title = searchParams.get('title');

  const userId = useAppSelector((state) => state.user.user?.userId as string);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Auth.getRoleInfoForUser(userId, roleId as string);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoleInfo(responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }

        callToast({
          title: 'Error',
          children: <>Something went wrong. Please try again later</>,
          variation: 'error',
        });

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  return (
    <div className={styles['role']}>
      <NavigationLink
        className={styles['role__back-link']}
        path="/account/profile"
        iconLeft={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        withBorder={false}
      >
        Back to Profile
      </NavigationLink>

      <div className={styles['title-container']}>
        <h3 className={classNames(styles['title-container__text'], 'text text--h3')}>{title}</h3>

        <MainButton
          sizeType="medium"
          visualType="white"
          iconLeft={<EditIcon style={{ fill: 'var(--color-main)', width: '13px' }} />}
          onClick={() => {
            navigate(`/account/profile/role/edit?roleId=${roleId}&title=${title}`);
          }}
        >
          Edit
        </MainButton>
      </div>

      <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
        <div className={styles['info__value-group']}>
          {roleInfo?.map((item, i) => {
            return (
              <div className={styles['info__value-group']} key={i}>
                <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
                  {item.propertyName}
                </div>

                <p className={classNames(styles['info__text'], 'text text--body-2')}>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>

      <h3 className={classNames(styles['title-container__text'], 'text text--h3')} style={{ marginTop: '64px' }}>
        HOW IT SHOULD BE STRUCTURED (work in progress):
      </h3>

      <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
        <div className={styles['info__value-group']}>
          <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Current Owned Company</div>

          <p className={classNames(styles['info__text'], 'text text--body-2')}>Founded it</p>
        </div>

        <div className={styles['info__value-group']}>
          <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Company Name</div>

          <p className={classNames(styles['info__text'], 'text text--body-2')}>Acme Corporation</p>
        </div>

        <div className={styles['info__value-group']}>
          <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Company Webiste</div>

          <p className={classNames(styles['info__text'], 'text text--body-2')}>
            <NavigationLink
              path="https://acme-corp.com/"
              colorType="dark"
              sizeType="small-2"
              iconRight={<ExternalLinkIcon style={{ width: '14px' }} />}
              target="_blank"
              rel="nofollow"
            >
              www.acme-corp.com
            </NavigationLink>
          </p>
        </div>

        <div className={styles['info__value-group']}>
          <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Title</div>

          <p className={classNames(styles['info__text'], 'text text--body-2')}>CEO</p>
        </div>
      </div>

      <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
        <div className={styles['info__value-group__list']}>
          <div className={styles['info__value-group']}>
            <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
              Most Relevant Business Type
            </div>

            <p className={classNames(styles['info__text'], 'text text--body-2')}>Hardware</p>
          </div>

          <div className={styles['info__value-group']}>
            <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
              Most Relevant Industry
            </div>

            <p className={classNames(styles['info__text'], 'text text--body-2')}>Telecommunications</p>
          </div>

          <div className={styles['info__value-group']}>
            <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Annual Revenue</div>

            <p className={classNames(styles['info__text'], 'text text--body-2')}>$100k - $250k</p>
          </div>

          <div className={styles['info__value-group']}>
            <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Funding Stage</div>

            <p className={classNames(styles['info__text'], 'text text--body-2')}>Raised series A</p>
          </div>

          <div className={styles['info__value-group']}>
            <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>Growth Goals</div>

            <div className={styles['info__label-list']}>
              <Label text="Raise capital" visualStyle="white" />
              <Label text="Set goals" visualStyle="white" />
              <Label text="Find a cofounder" visualStyle="white" />
            </div>
          </div>
        </div>
      </div>

      <MainButton
        className={styles['remove-role-link']}
        iconLeft={<TrashCanIcon style={{ width: '16px' }} />}
        sizeType="medium"
        visualType="white"
      >
        Remove role
      </MainButton>
    </div>
  );
};

export default AccountRole;
