import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as AboutIll } from '../../asset/images/about_ill.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import MainButton from '../../components/buttons/MainButton/MainButton';

import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket_font.svg';
import { ReactComponent as AstronomerIcon } from '../../asset/images/icons/astronomer_font.svg';
import { ReactComponent as PlanetIcon } from '../../asset/images/icons/planet_font.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import ValuesSection from '../../components/sections/ValuesSection/ValuesSection';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';

interface AboutViewProps extends IWithUserRoleProps {}

const AboutView: FunctionComponent<AboutViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplate footerSectionVariation="var-1">
      <HeroSection
        secondaryTitle={<mark>About us</mark>}
        title="No one goes to the moon alone"
        text={
          <>
            <p>
              <mark>It’s never been more important to connect people.</mark> In an increasingly digital world, and with
              entrepreneurship as lonesome a journey as it can be, we’re passionate about building infrastructure to
              connect innovators at scale.
            </p>

            <p>
              Between introducing founders to the founders, builders, mentors, and investors who can help them be
              successful with their pursuits, we feel most fulfilled when fostering authentic human connection between
              others.
            </p>
          </>
        }
        sectionVariation="var-1-c"
        illustration={<AboutIll style={{ marginRight: 'auto', marginLeft: 'auto' }} />}
        buttonGroup={[
          !isUserLoggedIn ? (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join our galaxy
            </MainButton>
          ) : (
            <MainButton
              sizeType="large"
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          ),
          <MainButton
            visualType="blank"
            sizeType="large"
            onClick={() => {
              router.navigate(`/${user_role}/${RoutesEnum.PRICING}`);
            }}
          >
            How it works
          </MainButton>,
        ]}
        markTagColor="blue"
      />

      <SectionDivider color="white" />

      <ChildrenWithHeaderSection backgroundColor="white">
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Vision"
              text="To explore the universe and colonize other planets."
              icon={<RocketIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Vision"
              text="To enable as much entrepreneurship as possible."
              icon={<AstronomerIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
            <TitleWithIconAndTextCard
              title="Purpose"
              text="To connect the world. To foster empathy at scale, with entrepreneurship as a vehicle for positive social change."
              icon={<PlanetIcon />}
              cardVariation="var-1"
              cardContentSize="large"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Diversity, Equity, Inclusion, and Belonging (DEIB)"
            titleSize="h3"
            text={
              <>
                At Astronomic, we’re building an <mark>open</mark>, <mark>collaborative</mark>, and{' '}
                <mark>inclusive</mark> culture that values each person’s unique contributions in the collective
                foundation of our company, community, and customers.
              </>
            }
          />
        }
      ></ChildrenWithHeaderSection>

      <SectionDivider color="blue" />

      <HeroSection
        title={
          <>
            Built with 🚀 in <br />
            Austin, TX
          </>
        }
        titleSize="h2"
        text="Astronomic was founded in June 2020 when people-connecting was constrained by the COVID pandemic. Our good friend Sam Ulu (CEO at Candidly) needed help meeting investors. We built a global database of investors and wrote a software algorithm to automate outreach to them from Sam’s email address. Realizing the power of data accessibility and the importance of connection, Astronomic was born. "
        sectionVariation="var-1-c-2"
        wrapperClass={null}
        imageUrl={require('../../asset/images/about_photo.jpg')}
      />

      <SectionDivider color="gray" />

      <ValuesSection
        title="Our Values"
        text="In our orbit, entrepreneurs are “astronomers” who uphold the following values."
        sectionVariation="var-1"
        sectionBackgroundColor="gray"
        itemsInRow={5}
        itemsData={[
          {
            title: 'Humility',
            text: 'We’re open to hearing critical feedback',
          },
          {
            title: 'Courage',
            text: 'We summon the strength to persevere',
          },
          {
            title: 'Integrity',
            text: 'To include, to adopt, to be open',
          },
          {
            title: 'Ambition',
            text: 'We dream big and push hard to get there',
          },
          {
            title: 'Diversity',
            text: 'We embrace different people and ideas ',
          },
        ]}
      />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <h2 className="text text--h2">Testimonials</h2>
          </SimpleTitle>
        }
        backgroundColor="gray"
      >
        <Slider
          sliderSlides={[
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(AboutView);
