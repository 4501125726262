import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Label from '../../../../../components/texts/Label/Label';
import styles from './AccountRoleAdd.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CompleteProfileTemplate from '../../../CompleteProfileTemplate/CompleteProfileTemplate';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import { AxiosError } from 'axios';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import { ApiResponseDTO } from '../../../../../dto/api';
import { ReactComponent as PersonIcon } from '../../../../../asset/images/icons/person_add.svg';
import RoutesEnum from '../../../../../enums/routes';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import CheckboxTiledList from '../../../../../components/inputs/CheckboxTiledList/CheckboxTiledList';

interface AccountRoleAddProps {}

const AccountRoleAdd: FunctionComponent<AccountRoleAddProps> = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.user?.userId as string);

  const [roles, setRoles] = useState<Array<any>>([]);
  const [checkboxFilterDataId, setCheckboxFilterDataId] = useState<string[]>([]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Auth.getRoles(userId);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          console.log(responseData.data);
          const formattedRoles = responseData.data.map((item) => {
            return { label: item.roleName, disabled: item.hasRole, value: item.roleId };
          });

          setRoles(formattedRoles);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          alert(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  const handleRoleOnChange = (data) => {
    setCheckboxFilterDataId(data);
  };

  return (
    <div className={styles['role']}>
      <div className={styles['title-container']}>
        <TitleWithIcon
          className={styles['title']}
          icon={<PersonIcon style={{ fill: 'var(--color-main)', width: '32px' }} />}
        >
          <h3 className={'text text--h3'}>Add role</h3>
        </TitleWithIcon>
      </div>

      <p className={classNames(styles['sec-title'], 'text text--body-1')}>Select a role that you identify with.</p>

      <main className={styles['main']}>
        <CheckboxTiledList data={roles} name={'role'} onChange={handleRoleOnChange} />
      </main>

      <footer className={styles['footer']}>
        <div className={styles['footer__button-group']}>
          <MainButton
            sizeType="large"
            visualType="white"
            onClick={() => {
              navigate(`/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}`);
            }}
          >
            Cancel
          </MainButton>

          <MainButton sizeType="large" visualType="main" onClick={() => navigate(`/account/profile/role/add/quiz`)}>
            Continue
          </MainButton>
        </div>
      </footer>
    </div>
  );
};

export default AccountRoleAdd;
