import { FunctionComponent, useState, useEffect } from 'react';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import { ReactComponent as PersonIcon } from '../../../../../asset/images/icons/person.svg';
import { ReactComponent as PlusIcon } from '../../../../../asset/images/icons/plus.svg';
import { ReactComponent as AccountIcon } from '../../../../../asset/images/icons/account.svg';
import { ReactComponent as UploadIcon } from '../../../../../asset/images/icons/upload.svg';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as EyeIcon } from '../../../../../asset/images/icons/eye.svg';
import { ReactComponent as TrashCanIcon } from '../../../../../asset/images/icons/trash_can.svg';
import { ReactComponent as LensIcon } from '../../../../../asset/images/icons/lens.svg';
import classNames from 'classnames';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import styles from './AccountProfile.module.scss';
import Grid from '../../../../../components/blocks/Grid/Grid';
import Label from '../../../../../components/texts/Label/Label';
import Input from '../../../../../components/inputs/Input/Input';
import { createPortal } from 'react-dom';
import PopUp from '../../../../../components/popups/PopUp/PopUp';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacesAutocompleteInput from '../../../../../components/inputs/PlacesAutocompleteInput/PlacesAutocompleteInput';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import RoutesEnum from '../../../../../enums/routes';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';

interface AccountProfileProps {}

const AccountProfile: FunctionComponent<AccountProfileProps> = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState<Array<any>>([]);
  const [deleteAccPopUpActive, setDeleteAccPopUpActive] = useState(false);
  const changeDeleteAccPopUpActiveState = (state) => {
    setDeleteAccPopUpActive(state);
  };

  const firstName = useAppSelector((state) => state.user.user?.firstName);
  const lastName = useAppSelector((state) => state.user.user?.lastName);
  const email = useAppSelector((state) => state.user.user?.email);
  const city = useAppSelector((state) => state.user.user?.city);
  const zipCode = useAppSelector((state) => state.user.user?.zipCode);
  const userId = useAppSelector((state) => state.user.user?.userId as string);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);

      if (anchor) {
        const headerHeight = 120;
        window.scrollTo({
          top: anchor.getBoundingClientRect().top + window.scrollY - headerHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Auth.getRoles(userId);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoles(responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          alert(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  console.log(roles);

  return (
    <div className={styles['profile']} style={{ position: 'relative' }}>
      <div className={styles['roles']}>
        <header className={styles['roles__header']}>
          <TitleWithIcon
            className={styles['title']}
            icon={<PersonIcon style={{ fill: 'var(--color-main)', width: '24px' }} />}
          >
            <h3 className={'text text--h3'}>Roles</h3>
          </TitleWithIcon>

          <MainButton
            className={styles['roles__header__add-company-button']}
            sizeType="medium"
            visualType="white"
            iconLeft={<PlusIcon />}
            onClick={() => {
              navigate(`/account/profile/role/add`);
            }}
          >
            Add role
          </MainButton>
        </header>

        <div className={styles['roles__list']}>
          <Grid
            itemsInRow={3}
            gridHorizontalMargin={3}
            itemGroup={roles
              .filter((item) => item.hasRole === true)
              .map((item, i) => (
                <div className={classNames(styles['roles__list__item'], 'tile tile--no-shadow')} key={item.roleId}>
                  <p className="text text--h5">{item.roleName}</p>

                  <MainButton
                    className={styles['roles__list__item__button']}
                    visualType="white"
                    sizeType="medium"
                    onClick={() =>
                      navigate(
                        `/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}/${RoutesEnum.ROLE}?roleId=${item.roleId}&title=${item.roleName}`
                      )
                    }
                  >
                    View details
                  </MainButton>
                </div>
              ))}
          />
        </div>
      </div>

      <div className={styles['account']} id="account">
        <TitleWithIcon
          className={styles['title']}
          icon={<AccountIcon style={{ fill: 'var(--color-main)', width: '24px' }} />}
        >
          <h3 className={'text text--h3'}>Account</h3>
        </TitleWithIcon>
      </div>

      <div className={classNames(styles['account__personal-info'], 'tile tile--no-shadow tile--padding-32px')}>
        <p className={classNames(styles['title-2'], 'text text--caps-small')}>Personal Information</p>

        <div className={styles['account__personal-info__avatar-container']}>
          <div className={styles['account__personal-info__avatar-container__image']}>
            <img src={require('../../../../../asset/images/avatar.png')} alt="Avatar" />
          </div>

          <div className={styles['account__personal-info__avatar-container__input']}>
            <MainButton
              className={styles['account__personal-info__avatar-container__input__button']}
              visualType="white"
              sizeType="medium"
              iconLeft={<UploadIcon />}
            >
              Upload Photo
            </MainButton>

            <p
              className={classNames(styles['account__personal-info__avatar-container__input__tip'], 'text text--tiny')}
            >
              Max 500x500px .PNG or .JPEG
            </p>
          </div>
        </div>

        <div className={classNames(styles['account__personal-info__input-group'], 'input-layout')}>
          <div className={styles['account__personal-info__input-list']}>
            <div className="input-layout__row input-layout__row--two-col">
              <div className="input-layout__row__col">
                <Input label="First Name" size="large" name="first_name" value={firstName} />
              </div>

              <div className="input-layout__row__col">
                <Input label="Last Name" size="large" name="last_name" value={lastName} />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--two-col input-layout__row--medium-margin">
              <div className="input-layout__row__col">
                <Input
                  label="Email"
                  size="large"
                  name="email"
                  value={email}
                  disabled={true}
                  iconLeft={<MailIcon style={{ width: '22px', fill: 'var(--color-gray-700)' }} />}
                />
              </div>

              <div className="input-layout__row__col">
                <Input label="LinkedIn" size="large" name="linkedin" value="VALUE_FROM_GET_USER_INFO?" />
              </div>
            </div>

            <div className="input-layout__row input-layout__row--flexible-col input-layout__row--medium-margin">
              <div className="input-layout__row__col">
                <PlacesAutocompleteInput label="City" placeholder={city} size="large" />
              </div>

              <div className="input-layout__row__col" style={{ maxWidth: '190px' }}>
                <Input label="Postal Code" size="large" name="postal_code" value={zipCode} />
              </div>
            </div>
          </div>
        </div>

        <MainButton className={styles['account__submit']} sizeType="medium">
          Save Changes
        </MainButton>
      </div>

      <div className={classNames(styles['account__change-pass'], 'tile tile--no-shadow tile--padding-32px')}>
        <p className={classNames(styles['title-2'], 'text text--caps-small')}>Change password</p>

        <div className={classNames(styles['account__change-pass__input-group'], 'input-layout')}>
          <div className={styles['account__change-pass__input-list']}>
            <div className="input-layout__row input-layout__row--two-col">
              <div className="input-layout__row__col">
                <Input
                  label="New password"
                  size="large"
                  name="pass_1"
                  value="!sdfk4K"
                  type="password"
                  iconRight={<EyeIcon />}
                />
              </div>

              <div className="input-layout__row__col">
                <Input
                  label="Repeat new password"
                  size="large"
                  name="pass_1"
                  value="!sdfk4K"
                  type="password"
                  iconRight={<EyeIcon />}
                />
              </div>
            </div>
          </div>
        </div>

        <MainButton className={styles['account__submit']} sizeType="medium">
          Save Changes
        </MainButton>
      </div>

      <MainButton
        className={styles['delete-account']}
        visualType="white"
        iconLeft={<TrashCanIcon />}
        onClick={() => {
          changeDeleteAccPopUpActiveState(true);
        }}
      >
        Delete account
      </MainButton>

      <ComingSoonMessage type="overlay" />

      {createPortal(
        <PopUp
          className={styles['delete-acc-pop-up']}
          active={deleteAccPopUpActive}
          onPopUpStateChange={changeDeleteAccPopUpActiveState}
          footer={
            <div className={styles['delete-acc-pop-up__footer-button-list']}>
              <div className={styles['delete-acc-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="main"
                  onClick={() => {
                    changeDeleteAccPopUpActiveState(false);
                  }}
                >
                  Keep my account
                </MainButton>
              </div>

              <div className={styles['delete-acc-pop-up__footer-button-list__item']}>
                <MainButton visualType="white">Delete account</MainButton>
              </div>
            </div>
          }
        >
          <div className={styles['delete-acc-pop-up__icon-container']}>
            <TrashCanIcon style={{ fill: 'var(--color-red-400)', width: '30px' }} />
          </div>

          <p className={classNames(styles['delete-acc-pop-up__title'], 'text text--h4')}>Delete account</p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-1'],
              'text text--body-2'
            )}
          >
            Are you sure you want to delete your account? <b>We're sad to see you go</b>! By deleting you account,
            you'll miss out on:
          </p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-2'],
              'text text--body-2'
            )}
          >
            1. Earned Rocket Fuel Credits for discounts and services. <br />
            2. Exclusive discounts and promotions. <br />
            3. Expert Services to help scale your business.
          </p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-3'],
              'text text--body-2'
            )}
          >
            Consider the long-term benefits you'll be forfeiting.{' '}
            <b>If you have any concerns, please contact our support team</b>. We value your presence in our community
            and hope you'll reconsider.
          </p>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </div>
  );
};

export default AccountProfile;
