import { FC } from "react";
import classNames from "classnames";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Slider.module.scss";

interface SliderProps {
  sliderSlides: React.ReactElement[];
}

const Slider: FC<SliderProps> = ({ sliderSlides }) => {
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={classNames(styles["slider"])}>
      <SlickSlider {...sliderSettings}>
        {sliderSlides.map((item, i) => (
          <div className={classNames(styles["slider__item"])} key={i}>
            {item}
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default Slider;
