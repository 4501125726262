import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./ValuesSection.module.scss";
import Grid, { GridProps } from "../../blocks/Grid/Grid";
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from "../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate";

type ItemsDataType = {
  title: string;
  text: string;
};

interface ValuesSectionProps extends Pick<GridProps, "itemsInRow"> {
  sectionVariation?: "var-0" | "var-1";
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
  className?: string;
  title: string;
  text: string;
  itemsData: ItemsDataType[];
}

const ValuesSection: FunctionComponent<ValuesSectionProps> = ({
  sectionVariation = "var-0",
  sectionBackgroundColor = "gray",
  itemsInRow = 3,
  className,
  title,
  text,
  itemsData,
}) => {
  return (
    <section
      className={classNames(
        styles["section"],
        styles[`section--${sectionVariation}`],
        className
      )}
    >
      <BackgroundSectionTemplate
        color={sectionBackgroundColor}
      ></BackgroundSectionTemplate>

      <div className="wrapper">
        <div className="content">
          <div className={classNames(styles["block-list"])}>
            <div className={classNames(styles["block-list__item-1"])}>
              <div className={classNames(styles["title"])}>
                <h2 className="text text--h2">{title}</h2>
              </div>

              <div className={classNames(styles["text"])}>
                <p className="text text--body-1">{text}</p>
              </div>
            </div>
            <div className={classNames(styles["block-list__item-2"])}>
              <div className={classNames(styles["element-list"])}>
                <Grid
                  itemsInRow={itemsInRow}
                  gridVerticalMargin={2}
                  itemGroup={itemsData.map((item, i) => (
                    <div
                      className={classNames(styles["element-list__item"])}
                      key={i}
                    >
                      <div
                        className={classNames(
                          styles["element-list__item__line"]
                        )}
                      ></div>
                      <div
                        className={classNames(
                          styles["element-list__item__title"]
                        )}
                      >
                        <h4 className="text text--h4">{item.title}</h4>
                      </div>
                      <div
                        className={classNames(
                          styles["element-list__item__text"]
                        )}
                      >
                        <p className="text text--body-2">{item.text}</p>
                      </div>
                    </div>
                  ))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;
