import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import Grid from '../../components/blocks/Grid/Grid';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';

import { ReactComponent as MainIll } from '../../asset/images/connect_ill_2.svg';
import { ReactComponent as CalendarIcon } from '../../asset/images/icons/calendar_2.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as PersonCheckIcon } from '../../asset/images/icons/person_check.svg';
import { ReactComponent as PinIcon } from '../../asset/images/icons/pin.svg';
import { ReactComponent as LightningIcon } from '../../asset/images/icons/lightning.svg';
import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people.svg';
import { ReactComponent as DollarIcon } from '../../asset/images/icons/dollar.svg';
import { ReactComponent as BubbleIcon } from '../../asset/images/icons/bubble.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';

interface SupernovaDinnersViewProps extends IWithUserRoleProps {}

const SupernovaDinnersView: FunctionComponent<SupernovaDinnersViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplate color="white">
      <HeroSection
        title="Pitch 20-30 matching investors at luxury homes"
        text="Meet 20-30 relevant investors or LPs over dinner at a gorgeous home. Must be post-revenue or raising a fund."
        sectionVariation="var-1-a"
        illustration={<MainIll />}
        buttonGroup={[
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          ),
          <MainButton
            sizeType={'large'}
            visualType="white"
            onClick={() => {
              router.navigate(`/${user_role}/${RoutesEnum.ABOUT}`);
            }}
          >
            What is Astronomic?
          </MainButton>,
        ]}
        breadcrumbs={<Breadcrumbs userRole={user_role} />}
      />

      <ChildrenWithHeaderSection title={<TitleWithSecTitle secTitle="A collision of value" title="Features" />}>
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Monthly invitations"
              text={
                <>
                  Our team will call, email, and work our networks to get 20-30{' '}
                  <b>relevant accredited investors RSVPd for your dinner</b>. You’ll receive all of their contact
                  information.
                </>
              }
              icon={<PeopleIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Foster FOMO for your raise"
              text={
                <>
                  We’ve found that the <b>intimate nature of a living room</b> setting enables sophisticated Q&A that
                  fosters FOMO for your raise.
                </>
              }
              icon={<BubbleIcon style={{ width: '20px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Approve each investor"
              text={
                <>
                  As soon as any investor expresses interest in your dinner, we’ll put them into a lead list.{' '}
                  <b>You’ll approve and deny each investor</b> who wants to attend your dinner.
                </>
              }
              icon={<PersonCheckIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Pitch practice and follow-ups"
              text={
                <>
                  Leading up to your dinner, <b>we’ll coach you on how to pitch and prepare for your dinner</b>. After
                  dinner, we’ll send follow-ups to each investor.
                </>
              }
              icon={<LightningIcon style={{ width: '16px', stroke: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <div className="invisible-divider"></div>

      <ChildrenWithHeaderSection title={<TitleWithSecTitle title="Format" />}>
        <Grid
          itemsInRow={3}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="30 days lead time"
              text="We need 30 days of lead time before we can facilitate your Supernova Dinner."
              icon={<CalendarIcon style={{ fill: '#F4B840', width: '24px' }} />}
              iconBgColor="#F4B84020"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="6-9pm"
              text="Investors arrive at 6pm with your pitch beginning at 6:30pm. Dinner is served at 7:30pm and the event wraps up at 9pm."
              icon={<TimeIcon style={{ fill: 'var(--color-main)', width: '29px' }} />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Any city anywhere"
              text="Choose the city you’d like to meet investors in, or let us recommend one. "
              icon={<PinIcon style={{ fill: 'var(--color-green)', width: '27px' }} />}
              iconBgColor="#80D0B820"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="1-5 team members"
              text="Bring as many as 5 team members to help you work the room and meet investors."
              icon={<PeopleIcon style={{ fill: '#7AD2E7', width: '32px' }} />}
              iconBgColor="#7AD2E720"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="No success fees"
              text="Supernova Dinners are fixed price; we don’t take a commission on your raise."
              icon={<DollarIcon style={{ fill: '#F4B840', width: '27px' }} />}
              iconBgColor="#F4B84020"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Dine with us!"
              text="Expedite the time it takes to meet 20-30 relevant investors."
              icon={<RocketIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
              iconBgColor="#EA6B3D20"
              link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <SectionDivider color="gray" />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <h2 className="text text--h2">Testimonials</h2>
          </SimpleTitle>
        }
        backgroundColor="gray"
      >
        <Slider
          sliderSlides={[
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(SupernovaDinnersView);
