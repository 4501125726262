import { FunctionComponent, useEffect } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../../../asset/images/astronomic_logo_2.svg';
import styles from './CompleteProfileHeader.module.scss';
import CompleteProfileHeaderStep from './_parts/CompleteProfileHeaderStepList/_parts/CompleteProfileHeaderStep/CompleteProfileHeaderStep';
import CompleteProfileHeaderStepList from './_parts/CompleteProfileHeaderStepList/CompleteProfileHeaderStepList';
import RocketFuel from '../../../../../components/blocks/RocketFuel/RocketFuel';

interface CompleteProfileHeaderProps {
  rocketFuel: number;
  currentStep: number;
  formSchema: any;
}

const CompleteProfileHeader: FunctionComponent<CompleteProfileHeaderProps> = ({
  rocketFuel,
  currentStep,
  formSchema,
}) => {
  return (
    <header className={styles['header']}>
      <div className="wrapper type-1">
        <div className={styles['content']}>
          <div className={styles['part-list']}>
            <div className={styles['part-list__left']}>
              <div className={styles['logo']}>
                <AstronomicLogo />
              </div>
            </div>

            <div className={styles['part-list__right']}>
              <div className={styles['steps']}>
                <CompleteProfileHeaderStepList currentStep={currentStep} formSchema={formSchema} />
              </div>

              <div className={styles['rocket-fuel']}>
                <RocketFuel number={rocketFuel} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['progress']}>
        <div
          className={styles['progress__current']}
          style={{ width: `${(currentStep * 100) / formSchema.length}%` }}
        ></div>
      </div>
    </header>
  );
};

export default CompleteProfileHeader;
