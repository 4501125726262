import React, { useEffect, useState, ComponentType } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserInfoAsyncThunk } from '../../../redux/slices/user/thunks';
import { localStorageGetUserAuthItems } from '../../../utils/localStorageMethods';

export interface AuthProps {
  isUserLoggedIn?: boolean;
}

const withAuthentication = <P extends AuthProps>(WrappedComponent: ComponentType<P>) => {
  const AuthWrapper: React.FC<P> = (props) => {
    const dispatch = useAppDispatch();
    const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
    const [isLoading, setIsLoading] = useState(true);
    const { at, rt, userId } = localStorageGetUserAuthItems();

    useEffect(() => {
      const checkAuthentication = async () => {
        if (userId) {
          if (!isUserLoggedIn && (at || rt)) {
            await dispatch(getUserInfoAsyncThunk(userId));
          }
        }

        setIsLoading(false);
      };

      checkAuthentication();
    }, []);

    if (isLoading) {
      return (
        <div style={{ padding: '24px 12px' }}>
          <p className="text text--h4">Loading...</p>
        </div>
      );
    }

    return <WrappedComponent {...props} isUserLoggedIn={isUserLoggedIn} />;
  };

  return AuthWrapper;
};

export default withAuthentication;
