import { forwardRef, FunctionComponent, useEffect, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import CompleteProfileHeader from './_parts/CompleteProfileHeader/CompleteProfileHeader';
import CompleteProfileFooter from './_parts/CompleteProfileFooter/CompleteProfileFooter';
import { ReactComponent as RocketIcon } from '../../../asset/images/icons/rocket.svg';
import classNames from 'classnames';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import PopUp from '../../../components/popups/PopUp/PopUp';
import styles from './CompleteProfileTemplate.module.scss';
import { RoleNames, Tags, TagsRocketFuel } from './utils/enums';
import CompleteProfileStep from './_parts/CompleteProfileStep/CompleteProfileStep';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { localStorageGetItem } from '../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../enums/localStorageKeys';
import RoutesEnum from '../../../enums/routes';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../dto/api';
import Input from '../../../components/inputs/Input/Input';
import Label from '../../../components/texts/Label/Label';
import RadioList from '../../../components/inputs/RadioList/RadioList';
import CheckboxList from '../../../components/inputs/CheckboxList/CheckboxList';
import useMediaQuery from '../../../hooks/useMediaQuery';

interface CompleteProfileTemplateProps {
  outputBySteps?: boolean;
  formDataInitial?: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideSidebar?: boolean;
  hideTitle?: boolean;
}

const CompleteProfileTemplate = forwardRef<unknown, CompleteProfileTemplateProps>(
  (
    {
      outputBySteps = true,
      formDataInitial = {},
      hideHeader = false,
      hideFooter = false,
      hideSidebar = false,
      hideTitle = false,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [formSchema, setFormSchema] = useState<any>([]);
    const [formData, setFormData] = useState(formDataInitial);

    const isTablet = useMediaQuery('(max-width: 1279px)');

    // STEPS
    const handleNextStep = () => {
      setCurrentStep(currentStep + 1);
    };

    const handlePrevStep = () => {
      setCurrentStep(currentStep - 1);
    };
    // STEPS END

    // POP UP
    const [skipPopUpActive, setSkipPopUpActive] = useState(false);

    const changeSkipPopUpActiveState = (state) => {
      setSkipPopUpActive(state);
    };
    // POP UP END

    // GET STEPS
    useEffect(() => {
      const getOnboardingSteps = async () => {
        if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID)) {
          try {
            const response = await api.Onboarding.getSteps(localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID) as string);
            const responseData = response.data;

            if (responseData.success === true && responseData.data) {
              setFormSchema(responseData.data);
            } else {
              console.error('Error:', responseData.errors);
              navigate(`/${RoutesEnum.WELCOME}`);
            }
          } catch (error) {
            const errorObj = error as AxiosError<ApiResponseDTO>;
            const errorData = errorObj.response?.data;
            if (errorData?.errorMessage) {
              console.error(errorData.errorMessage);
            }
            console.error('Error: ', error);
            navigate(`/${RoutesEnum.WELCOME}`);
          }
        } else {
          navigate(`/${RoutesEnum.WELCOME}`);
        }
      };

      getOnboardingSteps();
    }, []);
    // GET STEPS END

    const handleChange = (id: number, value: string, sectionId: number | null) => {
      setFormData({
        ...formData,
        [id]: { value: value, sectionId: sectionId },
      });
    };

    const handleSubmit = () => {
      const responses = Object.entries(formData).map(([key, value]) => {
        const { value: answer, sectionId } = value as any;

        return {
          questionId: parseInt(key),
          answer: Array.isArray(answer) ? answer.join(', ') : answer,
          sectionId: sectionId || null,
        };
      });

      console.log('RESPONSES: ', responses);

      const saveUserResponses = async () => {
        let success = true;

        if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID)) {
          try {
            const response = await api.Onboarding.saveUserResponses({
              userId: localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID) as string,
              responses: responses,
            });
            const responseData = response.data;

            if (responseData.success === true) {
              // navigate(`/${RoutesEnum.WELCOME}`);
            } else {
              success = false;
              console.error('Error:', responseData.errors);
              // navigate(`/${RoutesEnum.WELCOME}`);
            }
          } catch (error) {
            const errorObj = error as AxiosError<ApiResponseDTO>;
            const errorData = errorObj.response?.data;
            if (errorData?.errorMessage) {
              console.error(errorData.errorMessage);
            }
            console.error('Error: ', error);
            success = false;
            // navigate(`/${RoutesEnum.WELCOME}`);
          }
        } else {
          // navigate(`/${RoutesEnum.WELCOME}`);
        }

        return success;
      };

      return saveUserResponses();
    };

    const handleSubmitOuter = async () => {
      const success = await handleSubmit();

      if (success === true) {
        navigate(`/${RoutesEnum.WELCOME}`);
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const renderStep = () => {
      const step = formSchema.find((step) => step.stepOrder === currentStep);
      if (!step) return null;

      return [
        step.questions && step.questions.map((question) => renderQuestion(question, null)),
        step.sections && step.sections.map((section) => renderSection(section)),
      ];
    };

    const renderAllSteps = () => {
      return [
        formSchema.map((step, stepNum) => step.sections && step.sections.map((section) => renderSection(section))),
        formSchema.map(
          (step, stepNum) => step.questions && step.questions.map((question) => renderQuestion(question, null))
        ),
      ];
    };

    enum SectionNames {
      'Your Company' = 'section__form-layout--your-company',
      'Your Former Company' = 'section__form-layout--your-former-company',
    }

    const renderSection = (section) => {
      return (
        <div className={styles['section']} key={section.sectionID}>
          <p className={classNames(styles['section__title'], 'text text--caps')}>{section.sectionName}</p>

          <div className={classNames(styles['tile'], 'tile tile--no-shadow')}>
            <p className={classNames(styles['section__title-2'], 'text text--body-1 text--bold')}>
              <span className={styles['section__title-2__text']}>{'???'}</span>{' '}
              <Label text={`+$${section.rocketFuel} RF`} />
            </p>

            <div className={classNames(styles['section__form-layout'], styles[SectionNames[section.sectionName]])}>
              {section.questions.map((question) => renderQuestion(question, section))}
            </div>
          </div>
        </div>
      );
    };

    const renderQuestion = (question, section) => {
      switch (question.questionType) {
        case 'Text':
          const inputComponent = (
            <div className={styles['section__form-layout__input']}>
              <Input
                label={
                  <div className={styles['special-label']}>
                    <div className={styles['special-label__text']}>{question.displayName}</div>
                    {question.rocketFuel && <Label text={`+$${question.rocketFuel} RF`} />}
                  </div>
                }
                placeholder={question.placeholder}
                size="large"
                name={question.PropertyName}
                value={formData[question.questionID] ? formData[question.questionID].value : ''}
                onChange={(e) => handleChange(question.questionID, e.target.value, section ? section.sectionID : null)}
              />

              {question.description && (
                <p className={classNames(styles['tip-for-input'], 'text text--caption')}>{question.description}</p>
              )}
            </div>
          );

          return !section ? (
            <div className={styles['section']} key={question.questionID}>
              <div className={classNames(styles['tile'], 'tile tile--no-shadow')}>{inputComponent}</div>
            </div>
          ) : (
            inputComponent
          );
        case 'Choice':
          const choiceComponent = (
            <div className={styles['section__form-layout__input']}>
              <p className={classNames(styles['section__title-2'], 'text text--body-1 text--bold')}>
                <span className={styles['section__title-2__text']}>{question.displayName}</span>
                {question.rocketFuel && <Label text={`+$${question.rocketFuel} RF`} />}
              </p>

              {question.description ? (
                <p className={classNames(styles['section__text'], 'text text--caption')}>{question.description}</p>
              ) : (
                <p className={classNames(styles['section__text'], 'text text--caption')}>???</p>
              )}

              <div className={styles['section__form-layout__input__object']}>{renderChoice(question, section)}</div>
            </div>
          );

          return !section ? (
            <div className={styles['section']} key={question.questionID}>
              <div className={classNames(styles['tile'], 'tile tile--no-shadow')}>{choiceComponent}</div>
            </div>
          ) : (
            choiceComponent
          );
        default:
          return null;
      }
    };

    const renderChoice = (question, section) => {
      switch (question.choiceType) {
        case 'Radio':
          return (
            <RadioList
              name={question.questionID}
              sizeSmall={true}
              data={question.options.map((option) => {
                return { value: option, label: option };
              })}
              checkedValue={formData[question.questionID]?.value}
              className={classNames(styles['section__radio-list'], question.options.length >= 12)}
              onChange={(e) => {
                handleChange(question.questionID, e.value, section ? section.sectionID : null);
              }}
            />
          );
        case 'Checkbox':
          const checkboxListValues = question.options.map((option) => {
            return option;
          });

          const checkedValues = checkboxListValues.map((option) => {
            if (formData[question.questionID]) {
              if (formData[question.questionID].value.length) {
                return formData[question.questionID].value.some((option_2) => option === option_2);
              }
            }
          });

          return (
            <CheckboxList
              sizeSmall={true}
              data={question.options.map((option) => {
                return { value: option, label: option };
              })}
              dataToSetCheckedState={checkedValues}
              checkboxInitialData={
                formData[question.questionID] &&
                formData[question.questionID].value.map((option) => {
                  return { name: question.questionID, value: option, checked: true };
                })
              }
              className={classNames(styles['section__checkbox-list'])}
              name={question.questionID}
              onChange={(e) => {
                handleChange(
                  question.questionID,
                  e.map((checkboxItem, i) => checkboxItem.value),
                  section ? section.sectionID : null
                );
              }}
            />
          );
        default:
          return null;
      }
    };

    useEffect(() => {
      console.log('formData: ', formData);
      // console.log('formSchema: ', formSchema);
    }, [formData, currentStep, formSchema]);

    return (
      <div className={classNames(styles['template'], outputBySteps === false && styles['template--edit-role'])}>
        {hideHeader === false && (
          <CompleteProfileHeader rocketFuel={0} currentStep={currentStep} formSchema={formSchema} />
        )}

        <main className={styles['main']}>
          <div className="wrapper type-1">
            <div className={styles['content']}>
              <div className={styles['part-list']}>
                <div className={styles['part-list__main']}>
                  {hideTitle === false && (
                    <>
                      <div className={styles['sec-title']}>
                        <p className="text text--caps-small">Complete your profile</p>
                      </div>

                      <div className={styles['title']}>
                        <h3 className="text text--h3">Questions for each selected roles are combined</h3>
                      </div>

                      <p className={classNames(styles['sec-title-2'], 'text text--body-1')}>
                        Complete your profile so that we can relevantly serve your interests ⚡️
                      </p>

                      {hideSidebar === false && isTablet === true && (
                        <aside className={styles['part-list__side']}>
                          <div className={classNames(styles['info'], 'tile')}>
                            <h5 className={classNames(styles['info__title'], 'text text--h5')}>
                              Why is this important?
                            </h5>

                            <p className={classNames(styles['info__text'], 'text text--body-2')}>
                              Our goals are to serve your needs as relevantly, affordably, and efficiently as possible,
                              as well as to connect you with other founders, mentors, investors, and builders with
                              relevant experience. <br />
                              <br />
                              The more you’re comfortable sharing, the better-equipped we’ll be to make relevant
                              connections for you.
                            </p>

                            <div className={styles['info__button']}>
                              <MainButton sizeType="medium" visualType="white">
                                Learn more
                              </MainButton>
                            </div>
                          </div>
                        </aside>
                      )}
                    </>
                  )}

                  {/* <button onClick={handleSubmit}>TEST</button> */}

                  <div className={styles['part-list__main__outlet']}>
                    {outputBySteps === true ? renderStep() : renderAllSteps()}
                  </div>

                  {hideSidebar === false && isTablet === true && (
                    <aside className={styles['part-list__side']}>
                      <div className={classNames(styles['fuel-info'], 'tile', 'tile--color-main')}>
                        <p className={classNames(styles['fuel-info__text'], 'text text--caps-small')}>
                          Rocket Fuel earned
                        </p>

                        <p className={classNames(styles['fuel-info__number'], 'text text--h2')}>${'x'}</p>

                        <RocketIcon className={styles['fuel-info__icon']} />
                      </div>

                      <MainButton
                        className={styles['adaptive-skip-button']}
                        sizeType="large"
                        visualType="white"
                        onClick={() => changeSkipPopUpActiveState(true)}
                      >
                        Skip
                      </MainButton>
                    </aside>
                  )}
                </div>

                {hideSidebar === false && isTablet === false && (
                  <aside className={styles['part-list__side']}>
                    <div className={classNames(styles['info'], 'tile')}>
                      <h5 className={classNames(styles['info__title'], 'text text--h5')}>Why is this important?</h5>

                      <p className={classNames(styles['info__text'], 'text text--body-2')}>
                        Our goals are to serve your needs as relevantly, affordably, and efficiently as possible, as
                        well as to connect you with other founders, mentors, investors, and builders with relevant
                        experience. <br />
                        <br />
                        The more you’re comfortable sharing, the better-equipped we’ll be to make relevant connections
                        for you.
                      </p>

                      <div className={styles['info__button']}>
                        <MainButton sizeType="medium" visualType="white">
                          Learn more
                        </MainButton>
                      </div>
                    </div>

                    <div className={classNames(styles['fuel-info'], 'tile', 'tile--color-main')}>
                      <p className={classNames(styles['fuel-info__text'], 'text text--caps-small')}>
                        Rocket Fuel earned
                      </p>

                      <p className={classNames(styles['fuel-info__number'], 'text text--h2')}>${'x'}</p>

                      <RocketIcon className={styles['fuel-info__icon']} />
                    </div>
                  </aside>
                )}
              </div>
            </div>
          </div>
        </main>

        {createPortal(
          <PopUp
            className="skip-pop-up"
            title="Are you sure you want to skip?"
            active={skipPopUpActive}
            onPopUpStateChange={changeSkipPopUpActiveState}
            footer={
              <div className={styles['skip-pop-up__footer-button-list']}>
                <div className={styles['skip-pop-up__footer-button-list__item']}>
                  <MainButton
                    onClick={() => {
                      changeSkipPopUpActiveState(false);
                    }}
                  >
                    Complete Profile
                  </MainButton>
                </div>

                <div className={styles['skip-pop-up__footer-button-list__item']}>
                  <MainButton visualType="white" onClick={handleSubmitOuter}>
                    Skip, I'll do it later
                  </MainButton>
                </div>
              </div>
            }
          >
            <p className={classNames(styles['skip-pop-up__text-1'], 'text text--body-2')}>
              By completing each step, you can earn:
            </p>

            <div className={styles['skip-pop-up__fuel-tile']}>
              <div className={styles['skip-pop-up__fuel-tile__icon']}>
                <RocketIcon style={{ fill: 'var(--color-main)', width: '24px' }} />
              </div>

              <h4 className={classNames(styles['skip-pop-up__fuel-tile__text'], 'text text--h4')}>
                {`$${'x'} in Rocket Fuel Credits`}
              </h4>
            </div>

            <p className={classNames(styles['skip-pop-up__text-2'], 'text text--body-2')}>
              These credits can be used to pay for products and services. These forms will help us ensure that{' '}
              <b>the services you choose are tailored to your specific needs and preferences</b>.
            </p>

            <p className={classNames(styles['skip-pop-up__text-3'], 'text text--body-2')}>
              However, if you're in a rush, don't worry! You can always fill it out later in your profile page.
            </p>
          </PopUp>,
          document.getElementById('modal') as HTMLElement
        )}

        {hideFooter === false && (
          <CompleteProfileFooter
            onSkipPopUpStateChange={changeSkipPopUpActiveState}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            currentStep={currentStep}
            hideSkipButton={isTablet}
            formSchema={formSchema}
            onSubmit={handleSubmitOuter}
          />
        )}
      </div>
    );
  }
);

export default CompleteProfileTemplate;
