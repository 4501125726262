import RoutesEnum from '../enums/routes';

// type Category = "connect" | "build" | "learn" | "fund" | "grow";
export type Category = 'connect' | 'build';

export enum GrowthStage {
  iHaveAndIdea = 'g1',
  imReadyToBuild = 'g2',
  imReadyToLaunch = 'g3',
  allOtherStages = 'g4',
}

export const growthStageNames: Record<GrowthStage, string> = {
  [GrowthStage.iHaveAndIdea]: 'I have an idea',
  [GrowthStage.imReadyToBuild]: `I'm ready to build`,
  [GrowthStage.imReadyToLaunch]: `I'm ready to launch`,
  [GrowthStage.allOtherStages]: 'All other stages',
};

export enum Disciplines {
  strategy = 'd1',
  legal = 'd2',
  finance = 'd3',
  design = 'd4',
  software = 'd5',
  ventureStudio = 'd6',
  marketing = 'd7',
  sales = 'd8',
  fundraising = 'd9',
  operations = 'd10',
  marketValidation = 'd11',
  architecture = 'd12',
}

const disciplinesNames: Record<Disciplines, string> = {
  [Disciplines.strategy]: 'Strategy',
  [Disciplines.legal]: 'Legal',
  [Disciplines.finance]: 'Finance',
  [Disciplines.design]: 'Design',
  [Disciplines.software]: 'Software',
  [Disciplines.ventureStudio]: 'Venture Studio',
  [Disciplines.marketing]: 'Marketing',
  [Disciplines.sales]: 'Sales',
  [Disciplines.fundraising]: 'Fundraising',
  [Disciplines.operations]: 'Operations',
  [Disciplines.marketValidation]: 'Market Validation',
  [Disciplines.architecture]: 'Architecture',
};

export type subCategories = {
  disciplines?: Disciplines[];
  growthStage?: GrowthStage[];
  location?: [];
  time?: [];
};

export type Product = {
  title: string;
  description?: string;
  shortDescription: string;
  price?: string;
  link?: string;
  subCategories?: subCategories;
};

export type Products = {
  [key in Category]: {
    [key: string]: Product;
  };
};

const productDataByRoute: Partial<Record<RoutesEnum, Array<string> | string>> = {
  [RoutesEnum.CONSTELLATION_DINNERS]: 'connect_id_1',
};

const testProduct = [
  {
    id: `connect_id_1`,
    title: 'Constellation Dinners',
    shortDescription: 'Meet 5 other matching founders for dinner at restaurants in cities everywhere.',
    price: 'Free',
    link: `${RoutesEnum.CONNECT}/${RoutesEnum.CONSTELLATION_DINNERS}`,
    mainCategories: ['connect'],
    subCategories: ['bla', 'bla_2', 'inshiy_bla'],
  },
];

const productsData: Products = {
  connect: {
    [RoutesEnum.CONSTELLATION_DINNERS]: {
      title: 'Constellation Dinners',
      shortDescription: 'Meet 5 other matching founders for dinner at restaurants in cities everywhere.',
      price: 'Free',
      link: `${RoutesEnum.CONNECT}/${RoutesEnum.CONSTELLATION_DINNERS}`,
    },
    supernovaDinners: {
      title: 'Supernova Dinners',
      shortDescription:
        'Meet 20-30 relevant investors or LPs over dinner at a gorgeous home. Must be post-revenue or raising a fund.',
      price: '$15,000',
      link: `${RoutesEnum.CONNECT}/${RoutesEnum.SUPERNOVA_DINNERS}`,
    },
    nebulaDinners: {
      title: 'Galaxy',
      shortDescription:
        'Join our membership club for accredited investors. Deal flow, dinners, and connections concierge.',
      price: '$1,500/mo',
      link: `${RoutesEnum.CONNECT}/${RoutesEnum.GALAXY_DINNERS}`,
    },
  },
  build: {
    test_1: {
      title: `Open "I'm ready to build" tab`,
      description: '',
      shortDescription: 'Description',
      subCategories: {
        growthStage: [GrowthStage.iHaveAndIdea],
        disciplines: [Disciplines.architecture],
      },
    },

    [RoutesEnum.UI_UX_DESIGN]: {
      title: 'UI/UX design',
      description:
        'From low-fidelity wireframes to high-fidelity visual designs, our product designers will create gorgeous, designs of usable interfaces so that you can visualize what your website or app will look and feel like.',
      shortDescription: 'Do web research into competitors and opportunities and/or call prospective customers.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.UI_UX_DESIGN}`,
      subCategories: {
        disciplines: [Disciplines.design, Disciplines.marketing],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.PITCH_DECK_DESIGN]: {
      title: 'Pitch Deck Design',
      description: '-//-',
      shortDescription: 'We’ll design your deck, write your copy, create your illustrations, and beautify your graphs.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.PITCH_DECK_DESIGN}`,
      subCategories: {
        disciplines: [Disciplines.design],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.BRAND_IDENTITY_DESIGN]: {
      title: 'Brand Identity Design',
      description: '-//-',
      shortDescription: 'We’ll define your brand pillars, customer personas, and design your logo. ',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.BRAND_IDENTITY_DESIGN}`,
      subCategories: {
        disciplines: [Disciplines.design],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.WEB_DEVELOPMENT]: {
      title: 'Web Development',
      description: '-//-',
      shortDescription:
        'We’ll help you choose a tech stack or CMS, write your code, architect your database, and host your website.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.WEB_DEVELOPMENT}`,
      subCategories: {
        disciplines: [Disciplines.software],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.MOBILE_DEVELOPMENT]: {
      title: 'Mobile Development',
      description: '-//-',
      shortDescription:
        'We’ll help you choose a mobile framework, code your app, enable you to test it, and host it in the cloud.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.MOBILE_DEVELOPMENT}`,
      subCategories: {
        disciplines: [Disciplines.software],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.API_INTEGRATIONS]: {
      title: 'API Integrations',
      description: '-//-',
      shortDescription:
        'We’ll integrate your website or mobile app with third-party websites or apps for seamless data transfer.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.API_INTEGRATIONS}`,
      subCategories: {
        disciplines: [Disciplines.software],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.TECH_STACK]: {
      title: 'Tech Stack',
      description: '-//-',
      shortDescription:
        'We’ll help you choose a tech stack, from core languages to libraries and frameworks, given your use case.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.TECH_STACK}`,
      subCategories: {
        disciplines: [Disciplines.architecture],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.DATA_ARCHITECTURE]: {
      title: 'Data Architecture',
      description: '-//-',
      shortDescription: 'We’ll help you map your data architecture on the basis of your use case.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.DATA_ARCHITECTURE}`,
      subCategories: {
        disciplines: [Disciplines.architecture],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
    [RoutesEnum.DEV_SEC_OPS]: {
      title: 'DevSecOps',
      description: '-//-',
      shortDescription: 'We’ll help you get set up in the cloud with a secure infrastructure for deploying your code.',
      link: `${RoutesEnum.BUILD}/${RoutesEnum.DEV_SEC_OPS}`,
      subCategories: {
        disciplines: [Disciplines.architecture],
        growthStage: [GrowthStage.imReadyToBuild],
      },
    },
  },
};

export default productsData;
