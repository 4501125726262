import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as LearnIll } from '../../asset/images/learn_ill.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';

import { ReactComponent as LensIcon } from '../../asset/images/icons/lens.svg';
import { ReactComponent as PersonIcon } from '../../asset/images/icons/person.svg';
import { ReactComponent as ContractIcon } from '../../asset/images/icons/contract.svg';
import { ReactComponent as RetainerIcon } from '../../asset/images/icons/retainer.svg';
import { ReactComponent as DepositIcon } from '../../asset/images/icons/deposit.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import PriceBillet from '../../components/blocks/PriceBillet/PriceBillet';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import learnPageContent from '../../data/learn';

interface LearnViewProps extends IWithUserRoleProps {}

const LearnView: FunctionComponent<LearnViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplate>
      <HeroSection
        secondaryTitle="Learn"
        title="Learn from a founder who’s built a company like yours"
        text={learnPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<LearnIll />}
      />

      <SectionDivider color="gray" />

      <CardsWithHeaderSection
        title="Learn"
        text="Learn from founders who’ve built companies just like yours."
        sectionBackgroundColor="gray"
        sectionVariation="var-1"
        button={
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          )
        }
        customElement={
          <PriceBillet
            price="$250"
            linkText="How hourly billing works"
            linkUrl={`/${user_role}/${RoutesEnum.PRICING}`}
          />
        }
      >
        <Grid
          itemGroup={[
            <ServiceCard
              title="Fundraising Mentor"
              text="Learn from matching founders who are skilled with fundraising."
              price={null}
              link={`/${user_role}/${RoutesEnum.LEARN}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Sales Mentor"
              text="Learn from matching founders who are skilled with sales."
              price={null}
              link={`/${user_role}/${RoutesEnum.LEARN}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Marketing Mentor"
              text="Learn from matching founders who are skilled with marketing."
              price={null}
              link={`/${user_role}/${RoutesEnum.LEARN}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
          ]}
          itemsInRow={3}
        />
      </CardsWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Expertise" title="Been there, done that " />}
        backgroundColor="gray"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Expert matches"
              text="We match you with founders who’ve built, sold, or operationalized businesses that match your industry, revenue stage, model, and growth goal, so that you’re always having relevant conversations."
              icon={<LensIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Patient helpers"
              text="We carefully vet our advisors to make sure they are kind, compassionate, and understanding so that they can be both great helpers but also great listeners."
              icon={<PersonIcon style={{ width: '24px', fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No long-term contracts"
              text="We use a repeating 7-day contract so that if you need to stop, you can stop on a dime without being locked into a long-term commitment.."
              icon={<ContractIcon />}
            />,
            <TitleWithIconAndTextCard
              title="No retainers"
              text="We only work hourly with no monthly retainers, which means you pay only for what you need and nothing more."
              icon={<RetainerIcon style={{ fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No deposits"
              text="As long as you agree to our terms, we’ll get started working without you needing to put any money down."
              icon={<DepositIcon style={{ fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="No lead times"
              text="Need to get started ASAP? You got it. We can assemble a team and start your work within 24 hours."
              icon={<TimeIcon style={{ fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      <SectionDivider color="white" />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <h2 className="text text--h2">Testimonials</h2>
          </SimpleTitle>
        }
      >
        <Slider
          sliderSlides={[
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
            <TestimonialCard
              name="Adam Smith"
              role="Investor"
              text="What you guys are building is amazing."
              logoUrl={require('../../asset/images/candidly_logo.png')}
              photoUrl={require('../../asset/images/testimonial_avatar.png')}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(LearnView);
