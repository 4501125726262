import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './PreloadProductCard.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import PreloadLine from '../PreloadLine/PreloadLine';

interface PreloadProductCardProps {
  className?: string;
}

const PreloadProductCard: FunctionComponent<PreloadProductCardProps> = ({ className }) => {
  return (
    <article className={classNames(styles['subscription-card'], 'button-container-for-hover', className)}>
      <div className={styles['info-container']}>
        <div className={styles['info-container__top-part']}>
          <div className={styles['title']}>
            <h5 className="text text--h5">Preloading</h5>
            <PreloadLine />
          </div>

          <div className={styles['price']}>
            <p className="text text--caption">Preloading</p>
            <div className={styles['price__dot']}></div>
            <p className="text text--caption">Preloading</p>

            <PreloadLine />
          </div>
        </div>

        <div className={styles['info-container__bottom-part']}>
          <MainButton className={styles['button']} sizeType="medium" visualType="main">
            Preloading
            <PreloadLine />
          </MainButton>
        </div>
      </div>
    </article>
  );
};

export default PreloadProductCard;
