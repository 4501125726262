import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./BackgroundSectionTemplate.module.scss";

export type BackgroundSectionTemplateColorType = "white" | "gray" | "blue";

const BackgroundSectionTemplateColorMap: Record<
  BackgroundSectionTemplateColorType,
  string
> = {
  white: "",
  gray: "background--color-gray",
  blue: "background--color-blue",
};

interface BackgroundTemplateProps extends PropsWithChildren {
  className?: string;
  color?: BackgroundSectionTemplateColorType;
}

const BackgroundSectionTemplate: FunctionComponent<BackgroundTemplateProps> = ({
  children,
  className,
  color = "white",
}) => {
  return (
    <div
      className={classNames(
        styles["background"],
        "background",
        BackgroundSectionTemplateColorMap[color],
        className
      )}
    >
      {children}
    </div>
  );
};

export default BackgroundSectionTemplate;
