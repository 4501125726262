import { AxiosInstance, AxiosResponse } from 'axios';
import BaseAPI from '../base/api';
import { ApiResponseDTO } from '../../dto/api';

class OnboardingApi extends BaseAPI {
  private paths = {
    GET_STEPS: 'Onboarding/GetSteps',
    SAVE_USER_RESPONSES: 'Onboarding/SaveUserResponses',
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(axios: AxiosInstance, backend: string) {
    super(axios, backend);
  }

  async getSteps(userId: string) {
    const params = {
      userId: userId,
    };

    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_STEPS, { params });
  }

  async saveUserResponses(data: { userId: string; responses: Array<any> }) {
    return this.axios.post<ApiResponseDTO>(this.paths.SAVE_USER_RESPONSES, data);
  }
}

export default OnboardingApi;
